/* Reset some basic styling */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .footer-content-section {
    display: flex;
    width: 100%;
    background-color: black; /* Background color for the full-width section */
    text-align: center;
    justify-content: center;
  }
  
  .footer {
    background-color: black;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    width: 90%;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
    text-align: left;
  }
  
  .footer-logo img {
    max-width: 150px;
  }
  
  .footer-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .footer-column h3 {
    margin-bottom: 10px;
  }
  
  .footer-column ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-column ul li {
    margin: 5px 0;
  }
  
  .footer-column ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-bottom {
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }
  
  /* Mobile friendly adjustments */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: left;
      padding: 10px 0;
    }
  
    .footer-logo img {
      max-width: 120px; /* Adjust logo size for mobile */
      margin-bottom: 20px; /* Add margin below logo */
    }
  
    .footer-column {
      align-items: left; /* Center align footer columns */
      margin-bottom: 20px; /* Add margin below each column */
    }
  
    .footer-column h3 {
      text-align: left; /* Center align column headings */
    }
  
    .footer-column ul {
      text-align: left; /* Center align list items */
    }
  
    .footer-column ul li {
      margin: 5px 0;
    }
  
    .footer-bottom {
      padding: 20px 0; /* Increase padding for better spacing */
    }
  }
  