.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-bottom: 1px solid #eaeaea;
  max-width: 1400px;
  margin: 0 auto;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.logo img {
  width: 180px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}

.navigation {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  margin-left: 40px; /* Adjust the margin as needed */
  width: 100%; /* Ensure the navigation takes full width */
}

.nav-links {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-start; /* Ensure links are aligned to the start */
  font-family: "Arial", sans-serif; /* Use a clean, modern font */
}

.nav-links a {
  margin: 0 10px;
  text-decoration: none;
  color: black;
  font-size: 1rem; /* Set font size */
  font-weight: bold; /* Make text bold */
  white-space: nowrap; /* Prevent text from wrapping */
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-left: 20px; /* Add margin to the left */
  font-weight: bold;
}

.dropbtn {
  background: none;
  border: none;
  font-size: 1rem;
  color: black;
  cursor: pointer;
  margin: 20px;
  white-space: nowrap; /* Prevent text from wrapping */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.login {
  background: none;
  border: 2px solid black;
  padding: 15px 25px;
  margin-right: 10px; /* Add margin to the right */
  cursor: pointer;
  border-radius: 20px; /* Rounded corners */
  font-size: 1.1em; /* Adjust font size if needed */
  transition: background-color 0.3s, color 0.3s;
  font-weight: bold; /* Make text bold */
  margin-left: 20px;
}

.login:hover {
  background-color: black;
  color: white;
}

.register {
  background-color: #59a52c;
  color: white;
  border: none;
  padding: 15px 25px;
  cursor: pointer;
  border-radius: 20px; /* Rounded corners */
  font-size: 1.1rem; /* Adjust font size if needed */
  transition: background-color 0.3s, color 0.3s;
  font-weight: bold; /* Make text bold */
}

.register:hover {
  background-color: darkgreen;
}

.burger {
  display: none;
  font-size: 2rem; /* Default font size */
  cursor: pointer;
}

/* Mobile friendly adjustments */
@media (max-width: 768px) {
  .header {
    padding: 20px;
  }

  .header-top {
    justify-content: space-between;
    width: 100%;
  }

  .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .navigation {
    flex-direction: column;
    margin-left: 0;
    width: 100%;
    display: none; /* Hide navigation by default on mobile */
  }

  .navigation.open {
    display: flex; /* Show navigation when menu is open */
  }

  .nav-links {
    flex-direction: column;
    margin: 20px;
    margin-left: 30px;
    align-items: flex-start;
    width: 100%;
  }

  .nav-links a {
    margin: 10px 0;
  }

  .dropdown {
    margin-left: 0;
    width: 100%;
  }

  .dropbtn {
    margin: 0;
    margin-bottom: 10px;
  }

  .login,
  .register {
    width: 100%;
    margin: 10px 0;
    text-align: center;
  }

  .burger {
    display: block;
    font-size: 2rem; /* Larger font size for mobile view */
  }
}

/* Normal screen adjustments */
@media (min-width: 769px) {
  .header {
    flex-direction: row;
    justify-content: space-between;
  }

  .header-top {
    justify-content: space-between;
    width: 100%;
  }

  .logo {
    position: static;
    transform: none;
  }

  .navigation {
    flex-direction: row;
    margin-left: 40px;
    width: auto;
    display: flex; /* Ensure navigation is displayed on normal screens */
  }

  .nav-links {
    flex-direction: row;
    align-items: center;
    width: auto;
  }

  .nav-links a {
    margin: 0 10px;
  }

  .dropdown {
    margin-left: 20px;
    width: auto;
  }

  .dropbtn {
    margin-left: 20px;
  }

  .login,
  .register {
    width: auto;
    margin: 0 10px;
    text-align: center;
  }

  .burger {
    display: none; /* Hide burger menu on normal screens */
  }
}
