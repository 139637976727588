.main-section {
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  margin: 0 auto;

}

.section-one-wrapper {
  width: 100%;
  background-color: #8ab273;
}

.section-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 40px auto;
  margin-bottom: 160px;
  padding: 20px;
  width: 100%;
}

.intro-text {
  max-width: 100%;
  margin-bottom: 0px;
}

.intro-text h1 {
  font-size: 4.5rem;
  line-height: 1.2;
  color: black;
}

.intro-text span {
  color: black;
}

.mains-transfer-card {
  width: 100%;
  max-width: 480px;
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: auto;
}

.currency-text {
  font-size: 20px;
  font-weight: bold;
  margin-left: 5px;
}

.mains-form-group,
.amount-input-container,
.payment-section-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.mains-form-control,
.amount-input,
.form-control {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.amount-label,
.label,
.total-label {
  font-weight: bold;
  color: #555;
  margin-bottom: 2px;
  padding-bottom: 0;
  padding-top: 5px;
}

.amount-input-group {
  display: flex;
  align-items: center;
}

.value {
  font-size: 16px;
  color: #000;
  padding-right: 5px;
  margin-right: 5px;
}

.value-container {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.amount-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-top: 5px;
  padding-left: 10px;
}

.mains-fee,
.mains-total {
  font-weight: bold;
  font-size: 16px;
  color: #000;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.mains-input-group input {
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  font-size: 16px;
}

.mains-input-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mains-form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.mains-form-control {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
}

.mains-input-group-text {
  background-color: #eee;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 5px 5px 0;
  font-size: 16px;
  color: #555;
}

.form-control {
  border-radius: 5px;
  font-size: 16px;
}

.amount-input,
.total-value {
  flex: 1;
  padding: 10px;
  border: none;
  font-size: 16px;
  outline: none;
  text-align: left;
}

.divider {
  width: 2px;
  height: 36px;
  background-color: #ccc;
  margin: 0 10px;
}

.currency-display {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.currency-circle {
  width: 20px;
  height: 20px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 10px;
}

.mains-get-started {
  padding: 15px 25px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  width: 150px;
}

.mains-get-started:hover {
  background-color: #45a049;
}

.total-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.total-info {
  display: flex;
  flex-direction: column;
}

.total-value {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin-top: 0;
}

.amount-input {
  -moz-appearance: textfield;
}

.amount-input {
  flex: 1;
  border: none;
  font-size: 28px;
  outline: none;
  text-align: left;
  max-width: 45%;
}

.amount-input::-webkit-outer-spin-button,
.amount-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .section-one-wrapper {
    padding: 0; /* Remove padding to use the full width */
  }

  .section-one {
    flex-direction: column;
    align-items: center;
    width: 100%; /* Ensure the section spans the full width of the mobile screen */
    padding: 20px; /* Add some padding for mobile devices */
  }

  .intro-text h1 {
    font-size: 2.5rem;
    text-align: center;
  }

  .mains-transfer-card {
    padding: 20px;
    margin-top: 20px;
    width: 100%; /* Ensure the card takes the full width of the mobile screen */
    max-width: 100%; /* Remove max-width constraint */
  }

  .total-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .total-info {
    margin-bottom: 10px;
  }
}
