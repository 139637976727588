/* General styles for the body */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  /* Full-width section */
  .full-width-section {
    width: 100%;
    background-color: #ffffff; /* Background color for the full-width container */
    padding: 100px 0; /* Padding on top and bottom */
  }
  
  /* Centered content styles */
  .why-choose-us {
    margin: 0 auto; /* Center the content horizontally */
    padding: 0 20px; /* Add horizontal padding for responsiveness */
    text-align: center;
    max-width: 1300px;
    margin-bottom: 20px;
  }
  
  .why-choose-us h2 {
    font-size: 2.5em; /* Increase the font size */
    color: #333; /* Set the text color */
    margin-bottom: 30px; /* Add some space below the heading */
    font-weight: bold; /* Make the text bold */
    letter-spacing: 1px; /* Add some letter spacing */
  }
  
  /* Style for the reasons container */
  .reasons {
    display: flex;
    justify-content: space-around; /* Space out the cards evenly */
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
  }
  
  /* Style for the individual reason cards */
  .reason-card {
    margin: 19px;
    padding: 20px 40px; /* Use shorthand padding */
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow for better separation */
    text-align: center;
    flex: 1 1 30%; /* Flex properties for responsiveness */
  }
  
  /* Style for the icons */
  .icon {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .reason-card h3 {
    font-size: 1.6em; /* Increase the font size */
    color: #333; /* Set the text color */
    margin-bottom: 20px; /* Add some space below the heading */
    font-weight: bold; /* Make the text bold */
    text-transform: uppercase; /* Transform the text to uppercase */
    letter-spacing: 1px; /* Add some letter spacing */
  }
  
  .reason-card p {
    font-size: 1.2em; /* Increase the font size */
    color: #333; /* Set the text color */
    letter-spacing: 0; /* Adjust letter spacing */
  }
  
  /* Mobile friendly adjustments */
  @media (max-width: 768px) {
    .why-choose-us {
      padding: 0 10px; /* Reduce horizontal padding */
    }
  
    .why-choose-us h2 {
      font-size: 2em; /* Reduce font size */
    }
  
    .reasons {
      flex-direction: column; /* Stack cards vertically */
      align-items: center; /* Center align cards */
    }
  
    .reason-card {
      flex: 1 1 80%; /* Adjust flex properties for mobile */
      margin: 10px 0; /* Adjust margin for better spacing */
      padding: 20px; /* Reduce padding */
    }
  
    .reason-card h3 {
      font-size: 1.4em; /* Reduce font size */
    }
  
    .reason-card p {
      font-size: 1em; /* Reduce font size */
    }
  }
  