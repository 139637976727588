/* Full-width section */
.full-width-section-f {
    width: 100%;
    background-color: #ffffff; /* Background color for the full-width container */
    padding: 100px 0; /* Padding on top and bottom */
  }
  
  .flexible-options-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .flexible-options-section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: black;
  }
  
  .options-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1350px; /* Match max-width with the main section */
  }
  
  .option-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    margin: 40px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    flex: 1;
    max-width: 800px; /* Ensure both cards have the same max width */
    height: 320px; /* Set a fixed height to make them the same size */
    width: 540px;
  }
  
  .option-card h3 {
    color: green;
    margin-bottom: 20px;
    font-size: 2em;
  }
  
  .icons {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Create a 3-column grid */
    grid-template-rows: repeat(2, auto); /* Create two rows */
    gap: 10px;
    justify-items: center;
    align-items: center;
    height: 100%; /* Ensure the icons container takes the full height */
  }
  
  .icons img {
    max-width: 140px;
    max-height: 60px;
  }
  
  .iconss {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Create a 3-column grid */
    grid-template-rows: repeat(2, auto); /* Create two rows */
    gap: 10px;
    justify-items: center;
    align-items: center;
    height: 100%; /* Ensure the icons container takes the full height */
  }
  
  .iconss img {
    max-width: 220px;
    max-height: 90px;
  }
  .iconss .awash {
    max-width: 240px;
    max-height: 110px;
  }
  .iconss .telebirr {
    max-width: 200px;
    max-height: 80px;
    grid-column: 2; /* Place the Telebirr icon in the center column */
    grid-row: 2;
  }
  
  .icons .visa {
    max-width: 190px; /* Override the default max-width */
    max-height: 100px;
  }
  
  .icons .apple {
    max-width: 190px; /* Override the default max-width */
    max-height: 100px;
    grid-column: 1 / 2; /* Place Apple Pay in the first column of the second row */
    grid-row: 2;
  }
  .icons .paypal {
    max-width: 140px; /* Override the default max-width */
    max-height: 80px;
    grid-column: 2 / 3; /* Place PayPal in the second column of the second row */
    grid-row: 2;
  }
  
  .arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }
  
  .arrow {
    font-size: 2rem;
  }
  
  /* Mobile friendly adjustments */
  @media (max-width: 868px) {
    .full-width-section-f {
      padding: 50px 0; /* Reduce padding for smaller screens */
    }
  
    .flexible-options-section h2 {
      font-size: 2rem; /* Reduce font size for smaller screens */
      text-align: center;
    }
    .arrow {
        display: none;
        font-size: 2rem;
      }
  
    .options-container {
      flex-direction: column; /* Stack cards vertically on mobile */
      align-items: center;
    }
  
    .option-card {
      max-width: 90%; /* Reduce max width for smaller screens */
      margin: 20px 0; /* Reduce margin for smaller screens */
      width: 90%;
    }
  
    .icons {
      grid-template-columns: repeat(2, 1fr); /* Reduce to 2 columns on mobile */
      grid-template-rows: repeat(3, auto); /* Adjust rows for smaller screens */
    }
  
    .iconss {
      grid-template-columns: repeat(2, 1fr); /* Reduce to 2 columns on mobile */
      grid-template-rows: repeat(3, auto); /* Adjust rows for smaller screens */
    }
  
    .iconss .telebirr {
      grid-column: span 2; /* Span the Telebirr icon across both columns */
      grid-row: 2; /* Place in the second row */
    }
  
    .icons .apple {
      grid-column: span 2; /* Span the Apple Pay icon across both columns */
      grid-row: 2; /* Place in the second row */
    }
    .icons .paypal {
      grid-column: span 2; /* Span the PayPal icon across both columns */
      grid-row: 3; /* Place in the third row */
    }

    .iconss {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* Create a 3-column grid */
        grid-template-rows: repeat(2, auto); /* Create two rows */
        gap: 10px;
        justify-items: center;
        align-items: center;
        height: 100%; /* Ensure the icons container takes the full height */
      }

      .iconss img {
        max-width: 100px;
        max-height: 90px;
      }


      .icons img {
        max-width: 100px;
        max-height: 90px;
      }



  .icons {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Create a 3-column grid */
    grid-template-rows: repeat(2, auto); /* Create two rows */
    gap: 10px;
    justify-items: center;
    align-items: center;
    height: 100%; /* Ensure the icons container takes the full height */
  }
  .icons .apple {
    max-width: 190px; /* Override the default max-width */
    max-height: 100px;
    grid-column: 1 / 2; /* Place Apple Pay in the first column of the second row */
    grid-row: 2;
  }
  .icons .paypal {
    max-width: 140px; /* Override the default max-width */
    max-height: 80px;
    grid-column: 2 / 3; /* Place PayPal in the second column of the second row */
    grid-row: 2;
  }
  }
  