.App {
  font-family: Arial, sans-serif;
  color: #333;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
