/* General styles for the body */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.full-width-section-s {
  width: 100%;
  background-color: #8ab273; /* Background color for the full-width container */
  padding: 100px 0; /* Padding on top and bottom */
}

/* Section styles */
.how-to-send-money-section {
  margin: 0 auto; /* Center the content horizontally */
  width: 100%;
  text-align: center;
  max-width: 1300px;
}

.how-to-send-money-section h2 {
  font-size: 2.5em; /* Increase the font size */
  color: black; /* Set the text color */
  margin-bottom: 40px; /* Add some space below the heading */
  font-weight: bold; /* Make the text bold */
}

/* Steps container */
.steps {
  display: flex;
  justify-content: space-around; /* Space out the cards evenly */
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  gap: 20px; /* Add gap between the step cards */
  padding: 0 20px; /* Add horizontal padding */
}

/* Step card styles */
.step-card {
  flex: 1 1 calc(25% - 40px); /* Flexible width with gap consideration */
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow for better separation */
  text-align: center;
  margin: 20px 0; /* Add vertical margin for spacing between rows */
}

/* Step number styles */
.step-number {
  width: 50px;
  height: 50px;
  margin: 0 auto 20px auto; /* Center the step number */
  background-color: #0d6a3d; /* Background color for the step number */
  color: #fff; /* Text color for the step number */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em; /* Increase the font size */
  font-weight: bold; /* Make the text bold */
}

/* Step card heading styles */
.step-card h3 {
  font-size: 1.5em; /* Font size for the heading */
  color: #0d6a3d; /* Text color for the heading */
  margin-bottom: 10px; /* Space below the heading */
}

/* Step card paragraph styles */
.step-card p {
  font-size: 1em; /* Font size for the paragraph */
  color: black; /* Text color for the paragraph */
  line-height: 1.6; /* Increase the line height */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .step-card {
    flex: 1 1 100%; /* Full width on smaller screens */
  }
}
