/* General styles for the body */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  /* Section styles */
  .stay-connected-section {
    width: 100%;
    background-color: white; /* Background color for the full-width section */
    text-align: center;
  }
  
  .stay-connected-content {
    max-width: 1300px; /* Set a maximum width for the content */
    margin: 0 auto; /* Center the content horizontally */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #BB7988; /* Background color for the content area */
    border-radius: 8px; /* Optional: Rounded corners */
    padding: 20px;
    margin-bottom: 100px;
  }
  
  .text-content {
    flex: 1;
    padding: 20px;
  }
  
  .text-content h2 {
    font-size: 2.5em; /* Increase the font size */
    color: white; /* Set the text color */
    font-weight: bold; /* Make the text bold */
  }
  
  .text-content p {
    font-size: 1.2em; /* Set the font size */
    color: white; /* Set the text color */
    line-height: 1.6; /* Increase the line height */
  }
  
  .image-content {
    flex: 1;
    text-align: center;
  }
  
  .image-content img {
    max-width: 100%; /* Ensure the image does not exceed its container's width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Optional: Rounded corners */
    max-height: 400px; /* Set a maximum height for the image */
    object-fit: cover; /* Ensure the image covers the container without distortion */
  }
  
  .caption {
    margin-top: 20px;
    font-size: 1em;
    color: #777;
  }
  
  /* Mobile friendly adjustments */
  @media (max-width: 768px) {
    .stay-connected-content {
      flex-direction: column; /* Stack elements vertically on mobile */
      padding: 10px; /* Reduce padding for mobile */
      margin-bottom: 50px; /* Adjust margin for mobile */
    }
  
    .text-content {
      padding: 10px; /* Reduce padding for mobile */
      margin: 20px;
    }
  
    .text-content h2 {
      font-size: 2em; /* Reduce font size for mobile */
      margin-bottom: 40px;
    }
  
    .text-content p {
      font-size: 1em; /* Reduce font size for mobile */
    }
  
    .image-content img {
      max-height: none; /* Remove maximum height for mobile */
    }
  }
  